:root {
    --primary-color: #0056b3;
    --secondary-color: #e0e0e0;
    --text-color: #444444;
    --background-color: #ffffff;
    --border-color: #ccc;
    --accent-color-1: #333333;
    --accent-color-2: #999999;
    --footer-text-color: #ffffff;
    --footer-hover-color: #999999;
    --footer-line-color: #444444;
    --footer-background-color: #000000;
}
