:root {
    --modal-background-color: var(--background-color);
    --modal-border-color: var(--secondary-color);
    --overlay-background-color: rgba(0, 0, 0, 0.75);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--overlay-background-color);
    z-index: 1000;
}

.modal {
    position: fixed;
    background: var(--modal-background-color);
    border: 1px solid var(--modal-border-color);
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 90%;
    max-width: 900px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 600px;
    overflow-y: auto;
    z-index: 1001;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.closeButton:hover {
    transform: rotate(90deg);
}

@media (max-width: 600px) {
    .modal {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
        box-sizing: border-box;
        padding: 10px;
    }

    .modal p {
        text-align: left;
        padding: 0;
        margin: 10px 0 20px 0;
    }



    .closeButton {
        font-size: 30px;
    }
}

@media (max-width: 600px) {
    .modal {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
        box-sizing: border-box;
        padding: 30px 10px 10px;
    }

    .closeButton {
        font-size: 30px;
    }
}
