.socialMediaLink {
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: transform 0.3s ease;
    text-align: center;
    margin: 0 10px 20px;
}

.socialMediaLink:hover {
    transform: scale(1.1);
}

.socialMediaIcon {
    font-size: 1.8em;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #FFF;
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}
