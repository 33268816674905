:root {
    --background-color: var(--background-color);
    --text-color: var(--text-color);
    --border-color: var(--secondary-color);
    --icon-color: var(--text-color);
    --hover-color: var(--primary-color);
}

.cardElement {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 15px;
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
}

.cardElement:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardElement a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
}

.cardElement p {
    text-align: left;
}

.cardElementIcon {
    margin-right: 15px;
    font-size: 24px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--icon-color);
}

.cardElementIcon i {
    font-size: 1.5em;
    height: 36px;
}

.cardElementIcon img {
    max-width: 100%;
    height: 36px;
}

.cardTitle, .cardText {
    margin: 0;
}

.cardElement:hover .cardElementIcon i,
.cardElement:hover .cardTitle,
.cardElement:hover .cardText {
    color: var(--hover-color);
    transition: color 0.3s ease;
}

@media (max-width: 600px) {
    .cardElement {
        margin-bottom: -1px;
        border-radius: 0;
    }
}
