:root {
    --profile-border-color: var(--background-color);
}

.backgroundImage {
    top: 0;
    position: relative;
    height: 180px;
    width: 100%;
    object-fit: cover;
}

.profileContainer {
    position: relative;
    margin: -65px auto 0 auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid var(--profile-border-color);
    z-index: 1;
    background-color: var(--profile-border-color);
    overflow: hidden;
}

.profileImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileName {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

p {
    margin-top: 10px;
    font-size: 18px;
}

@media (max-width: 600px) {
    p {
        padding: 0 10px;
        text-align: center;
        margin: 0 10px;
    }
}
